import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const Lewandowski = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #960E0E, #B71616, #CD0808, #B71616, #960E0E )",
        icons: "#f40000",
        navClass: "lewandowski",
      }}
      seo={{
        title: "Lewandowski",
        headerTitle: "lewandowski",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/lewandowski_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/lewandowski/",
      }}
    >
      <PortfolioHeader name="lewandowski" height="125" />
      <section className="container-fluid lewandowski_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>
                Lewandowski <br /> Power Team
              </h1>
              <ul>
                <li>Logo</li>
                <li>Projekt opakowania</li>
              </ul>
            </div>
          </div>

          <div className="col-md-6">
            <div className="inner">
              <p>
                Naszym zadaniem było zaprojektowanie od podstaw nowego brandu na
                rynku napojów energetycznych. Zarówno logo jak i projekt puszki
                Lewandowski Power Team mają silny, zdecydowany charakter a
                czytelny znak, który przekazuje energię i dynamizm. Do
                projektowania puszek wykorzystaliśmy bardzo jasne i wyraziste
                kolory - w tym te nawiązujące do polskich barw narodowych: biel
                i czerwień.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid lewandowski_section_3">
        <div className="row">
          <div className="col-md-6 col_1">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/lewandowski_main_1.png")}
              alt=""
            />
            <div className="img_wrapper">
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/lewandowski_main_2.png")}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-4 offset-md-2 col_2">
            <h3>Logo</h3>
            <div className="row">
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Black CMYK</p>
                    <h5>
                      <span>0</span>
                      <span>0</span>
                      <span>0</span>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 100 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Black CMYK</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 30 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 30 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 30 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 100 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <div className="col-sm-6">
                <p>RGB</p>
                <h5>
                  <span>0</span>
                  <span>0</span>
                  <span>0</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>RGB:</p>
                <h5>
                  <span>0</span>
                  <span>0</span>
                  <span>0</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>000000</h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>000000</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid lewandowski_section_4">
        <div className="img_wrapper">
          <img
            className="img-fluid"
            src={require("../../assets/img/portfolio/lewandowski_main_3.png")}
            alt=""
          />
        </div>
        <img
          className="img-fluid"
          src={require("../../assets/img/portfolio/lewandowski_main_41.png")}
          alt=""
        />
        <img
          className="img-fluid img_hand"
          src={require("../../assets/img/portfolio/lewandowski_main_42.png")}
          alt=""
        />
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Lewandowski;
